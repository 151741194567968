* {
  margin: 0;
}

.time {
  display: flex;
  align-items: center;
  gap: 10px;
}

.errorText {
  color: red;
}